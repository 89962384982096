import { Price } from '../pricing/price';
import { PriceDetail } from '../pricing/price-detail';

export class BookingCoupon {
    idCompany: number;
    idBookingCoupon: number;
    idDailyScheduleRoute: number;
    idPartnerDailyScheduleRoute: number;
    idSeatMapCell: number;
    seatIdentifier: string;
    seatBookingIdentify: string;
    stockBookingIdentify: string;
    isWithOutSeat: boolean;
    ticketKey: string;
    price: Price;
    originalPrice: Price;
    priceDetail: PriceDetail;
    ticketNumber: number;
    idPassengerType: number;
    idPassengerTypeConfiguration: number;
    insuranceAvailable: boolean;
    requireExistingSeat: boolean;
    isGrouped: boolean;
    idPassengerClassification: number;
}
