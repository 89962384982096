export class CustomerProfileChangeEvent {
    readonly fullName: string;
    readonly document: string;
    readonly phone: string;
    readonly idCity: number;
    readonly idState: number;
    readonly birthday: string;
    readonly idCountry: number;
    readonly countryCode: string;

    constructor(
        fullName: string,
        document:string,
        phone: string,
        idCity: number,
        idState: number,
        birthday: string,
        idCountry: number,
        countryCode:string
    ) {
        this.fullName = fullName;
        this.document = document;
        this.phone = phone;
        this.idCity = idCity;
        this.idState = idState;
        this.birthday = birthday;
        this.idCountry = idCountry;
        this.countryCode = countryCode;
    }
}