export class LocationHelper {
    static beautifyLocationName = (name: string): string => {
        while (name.includes(' - ')) {
            name = name.replace(' - ', '-');
        }
        var ret = LocationHelper.removeDiacritics(name);

        ret = ret.toLowerCase().replace(/\s/g, '_')
                                    .replace(/_\(/g, '-')
                                    .replace(/([a-z])[(]/g, '$1-')
                                    .replace(/[\)]/g, '');

        return ret;
    }
    


    static removeDiacritics(s: string): string {
        var r = s.toLowerCase();
        r = r.replace(new RegExp(/[àáâãäå]/g), "a");
        r = r.replace(new RegExp(/æ/g), "ae");
        r = r.replace(new RegExp(/ç/g), "c");
        r = r.replace(new RegExp(/[èéêë]/g), "e");
        r = r.replace(new RegExp(/[ìíîï]/g), "i");
        r = r.replace(new RegExp(/ñ/g), "n");
        r = r.replace(new RegExp(/[òóôõö]/g), "o");
        r = r.replace(new RegExp(/œ/g), "oe");
        r = r.replace(new RegExp(/[ùúûü]/g), "u");
        r = r.replace(new RegExp(/[ýÿ]/g), "y");
        return r;
    }

    static restoreLocationName = (name: string, showAdditionalInfo: boolean = true): string => {
        if (!name) {
            return name;
        }

        let result = '';
        if (name.includes('_') || name.includes('-')) {
            if (name.includes('_')) {
                const parts = name.split('_');

                for (let i = 0; i < parts.length; i++) {
                    result += `${LocationHelper.capitalize(parts[i].trim())} `
                }
            }

            if (result == '') {
                result = name;
            }

            if (result.includes('-')) {
                const parts = result.split('-');
                result = '';
                for (let i = 0; i < parts.length; i++) {
                    if (parts[i].trim().length == 2 || parts[i].trim() == "todos") {
                        result += `${parts[i].toUpperCase()}`
                    } else {
                        result += `${LocationHelper.capitalize(parts[i].trim())}`
                    }

                    if (i < parts.length - 1) {
                        result += ' - ';
                    }
                }
            }
        }

        if (result == '' || result == name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }

        result = result.trim();

        return result;
    }

    static capitalize = (s): string => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1)
    }
}