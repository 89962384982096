import moment from 'moment';
import { OreonsDatetime } from '../models/helpers/oreons-datetime';

export class DateHelper {
    static areEqual = (d1: Date, d2: Date): boolean => {
        return d1.getFullYear() === d2.getFullYear()
            && d1.getMonth() === d2.getMonth()
            && d1.getDate() === d2.getDate();
    }

    static orensDateTimeAreEqual = (d1: OreonsDatetime, d2: OreonsDatetime): boolean => {
        return d1.hour === d2.hour
            && d1.minute === d2.minute;
    }

    static convertToDate = (d1: OreonsDatetime): Date => {
        if (!d1) {
            return null;
        }

        return new Date(d1.year, d1.month - 1, d1.day);
    }

    static convertToFormattedDateFromPtBr = (date: string, format: string): Date | any => {
        const result = moment(date, 'DD/MM/YYYY');
        return result.format(format);
    }

    static convertToFormattedDate = (date: Date, incomingFormat: string, outgoingFormat: string): Date | any => {
        const result = moment(date, incomingFormat);
        return result.format(outgoingFormat);
    }

    static convertOreonsDateTimeToISO = (date: OreonsDatetime): string => {
        return `${date.year}-${date.month}-${date.day}`;
    }

    static convertOreonsDateTimeToISOWithTime = (date: OreonsDatetime): string => {
        return `${date.year}-${date.month}-${date.day} ${date.hour}:${date.minute}`;
    }

    static convertToDateTime = (d1: OreonsDatetime): Date => {
        if (!d1) {
            return null;
        }

        return new Date(
            d1.year,
            d1.month - 1,
            d1.day,
            d1.day,
            d1.hour,
            d1.minute,
            0);
    }

    static convertToCalendarDate = (d1: OreonsDatetime): string => {
        const date = new Date(d1.year, d1.month - 1, d1.day);
        return DateHelper.beautifyDateToString(date);
    }

    static convertToCalendarDateWithSlash = (d1: OreonsDatetime): string => {
        var date: Date;
        if (d1 == null) {
            date = new Date(2000, 1, 1);
        } else {
            date = new Date(d1.year, d1.month - 1, d1.day);
        }
        return DateHelper.beautifyDateToStringWithSlash(date);
    }

    static convertToDateFromPtBrString = (date: string): Date => {
        if (date === '') return null;
        const d = date.split('/');
        if (d.length < 3) {
            return null;
        }

        return new Date(Number.parseInt(d[2]), Number.parseInt(d[1]) - 1, Number.parseInt(d[0]));
    }

    static beautifyDate = (date: string): string => {
        if (!date) { return date; }
        return date.replace(/\//g, '-')
    }

    static beautifyOreonsDatetime = (date: OreonsDatetime): string => {
        if (!date) { return ''; }
        return `${date.day}-${date.month}-${date.year}`;
    }

    static beautifyDateToString = (date: Date): string => {
        if (!date) {
            return '';
        }

        const newDate = new Intl.DateTimeFormat('pt-BR').format(date);
        return newDate.replace(/\//g, '-')
    }

    static beautifyDateToStringWithSlash = (date: Date): string => {
        if (!date) {
            return '';
        }

        const newDate = new Intl.DateTimeFormat('pt-BR').format(date)
        return newDate;
    }

    static beautifyDateFromString = (date: string): string => {
        const result = moment(date, 'DD/MM/YYYY');
        return result.format('DD-MM-YYYY');
    }

    static restoreDate = (date: string): string => {
        if (!date) { return date; }
        return date.replace(/\-/g, '/')
    }
}

