import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BrowserHelper } from 'src/app/main/helpers';
import { Feature } from 'src/app/main/models';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FeaturesComponent {
    @Input('features') features: Feature[];
    isMobile: boolean = false;
    displayFeatures: Feature[] = [];
    indexes: number[] = [];

    constructor(
        public browserHelper: BrowserHelper,
        private changeRef: ChangeDetectorRef) {
        this.isMobile = browserHelper.runningOnMobile();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.features && this.features.length > 0) {
            this.features.sort((a, b) => a.order - b.order);
            const maxIndex = this.isMobile ? 1 : 4;

            for (let i = 0; i <= maxIndex; i++) {
                this.indexes[i] = i;
            }

            this.populateFeatures();
        }
    }

    rotate = (left: boolean): void => {
        this.displayFeatures = [];
        const maxBoundIndex = this.features.length - 1;
        if (left) {
            for (let i = 0; i < this.indexes.length; i++) {
                if (this.indexes[i] === 0) {
                    this.indexes[i] = maxBoundIndex
                } else {
                    this.indexes[i] = this.indexes[i] - 1;
                }
            }
        } else {
            for (let i = 0; i < this.indexes.length; i++) {
                if (this.indexes[i] === maxBoundIndex) {
                    this.indexes[i] = 0
                } else {
                    this.indexes[i] = this.indexes[i] + 1;
                }
            }
        }
        this.populateFeatures();
        this.changeRef.markForCheck();
    }

    populateFeatures = (): void => {
        for (let i = 0; i < this.indexes.length; i++) {
            this.displayFeatures.push(this.features[this.indexes[i]]);
        }
    }

    setSlideEvent(event) {
        this.rotate(event.touchLeft)
    }
}