import { Injectable } from "@angular/core";
import { Observable, lastValueFrom } from 'rxjs';
import { Dropdown } from "../models";
import { DropdownSerializer, EmptySerializer } from "../serializers";
import { BaseApi } from "./base.api";
import { BaseService } from "./base.service";
import PassengerType from "../models/passanger/passenger-type";

@Injectable({
    providedIn: 'root'
})
export class PassengerTypeService {

    private api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'core/PassengerType');
    }


    public async GetAllPassengerTypesInPointOfSale(): Promise<PassengerType[]> {
        var ret = await lastValueFrom(this.api.get<PassengerType[]>('GetAllPassengerTypesInPointOfSale', new EmptySerializer()));
        return ret;
    }
}