import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class DocumentService {

    public constructor(
        public title: Title,
        public meta: Meta,
        @Inject(DOCUMENT) private doc: HTMLDocument
    ) {
    }

    public setHtmlLang(lang: string): void {
        this.doc.documentElement.setAttribute('lang', lang);
    }

    public addLink(type: string, rel: string, href: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const link = document.createElement("link");
            if (type) {
                link.type = type;
            }
            link.rel = rel;
            link.href = href;

            link.onload = () => {
                resolve();
            };

            link.onerror = (error: any) => {
                reject(error);
            };

            this.doc.head.appendChild(link);
        });
    }

    public addScript(scriptUrl: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const scriptEl = document.createElement('script');
            scriptEl.type = 'text/javascript';
            scriptEl.src = scriptUrl;

            scriptEl.onload = () => {
                resolve();
            };

            scriptEl.onerror = (error: any) => {
                reject(error);
            };

            this.doc.head.appendChild(scriptEl);
        });
    }

    public addContentScript(script: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const scriptEl = document.createElement('script');
            scriptEl.type = 'text/javascript';
            scriptEl.innerHTML = script;

            scriptEl.onload = () => {
                resolve();
            };

            scriptEl.onerror = (error: any) => {
                reject(error);
            };

            this.doc.head.appendChild(scriptEl);
        });
    }
}