import { Travel } from '../models/travel';

export class SearchEvent {
    event: string = 'searchResult';
    travel: Travel;
    ecommerce: any;

    constructor(
        departureLocation: string,
        departureDate: string,
        arrivalLocation: string,
        arrivalDate: string,
        cupon: string,
    ) {
        this.travel = {
            originBusStation: departureLocation,
            departingDepartureDate: departureDate,
            destinationBusStation: arrivalLocation,
            returningDepartureDate: arrivalDate,
            cuponCode: cupon
        };
        this.ecommerce = {
            checkout: {
                actionField: {
                    step: 1,
                }
            }
        }
    }
}