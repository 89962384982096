import { TripSearchData } from './trip-search-data';

export class RecentTrip {
    arrivalLocationCode: string;
    departureLocationCode: string;

    constructor(searchData: TripSearchData) {
        this.departureLocationCode = searchData.departureLocationName;
        this.arrivalLocationCode = searchData.arrivalLocationName;
    }
}