import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgbDate, NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatePickerLocale } from './custom-datepicker.locale';
import { DatePrice } from './models/date-price';

@Component({
    selector: 'app-custom-datepicker',
    templateUrl: './custom-datepicker.component.html'
})
export class CustomDatePickerComponent implements OnInit, OnChanges {
    @ViewChild('datepicker') datePicker: NgbDatepicker;
    @Output('departureDateSelected') departureDateEmitter: EventEmitter<string> = new EventEmitter();
    @Output('arrivalDateSelected') arrivalDateEmitter: EventEmitter<string> = new EventEmitter();
    @Input('rangeSelection') rangeSelection: boolean = false;
    @Input('minimumPriceRange') minimumPriceRange: DatePrice[] = [];
    @Input('locale') locale: CustomDatePickerLocale;
    @Input('minDate') outterMinDate: NgbDate = undefined;
    @Input('maxDate') outterMaxDate: NgbDate = undefined;
    @Input('subtitle') subtitle: string = 'ida';
    @Input('customFooterLabel') customFooterLabel: string;

    opened: boolean = false;
    fromDate: NgbDate;
    toDate: NgbDate;
    hoveredDate: NgbDate;
    display: boolean = false;

    ngOnInit(): void {
    }

    toggle = (): void => {
        this.display = !this.display;
    }

    getMinDate = (): NgbDate => {
        if (this.outterMinDate) {
            return this.outterMinDate;
        }

        const today = new Date();
        return new NgbDate(today.getFullYear(), today.getMonth() + 1, today.getDate());
    }

    getMaxDate = (): NgbDate => {
        if (this.outterMaxDate) {
            return this.outterMaxDate;
        }

        const today = new Date();
        return new NgbDate(today.getFullYear() + 1, today.getMonth() + 1, today.getDate());
    }

    onDateSelection(date: NgbDate) {
        if (!this.rangeSelection) {
            this.fromDate = undefined;
        }

        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.departureDateEmitter.emit(this.convertDateToString(this.fromDate, this.locale));
            if (!this.rangeSelection) {
                this.display = false;
            }
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
            this.arrivalDateEmitter.emit(this.convertDateToString(this.toDate, this.locale));
            this.display = false;
        } else {
            this.toDate = undefined;
            this.arrivalDateEmitter.emit('');
            this.fromDate = date;
            this.departureDateEmitter.emit(this.convertDateToString(this.fromDate, this.locale));
            if (!this.rangeSelection) {
                this.display = false;
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['rangeSelection']) {
            this.toDate = undefined;
            this.fromDate = undefined;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate) && this.rangeSelection;
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate) && this.rangeSelection;
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    convertDateToString = (date: NgbDate, locale: CustomDatePickerLocale): string => {
        switch (locale) {
            case CustomDatePickerLocale.pt_br:
                return `${date.day}/${date.month}/${date.year}`;
            default:
                return `${date.day}/${date.month}/${date.year}`;
        }
    }

    hasMinimumPrice = (date: NgbDate): boolean => {
        return this.minimumPriceRange.some(minPrice => minPrice.date.year == date.year
            && minPrice.date.month == date.month
            && minPrice.date.day == date.day);
    }

    getMinimumPrice = (date: NgbDate): number => {
        return this.minimumPriceRange.find(minPrice => minPrice.date.year == date.year
            && minPrice.date.month == date.month
            && minPrice.date.day == date.day).price;
    }
}
