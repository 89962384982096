import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { ImageHelper, LocationHelper } from '../../../helpers';
import { TripBanner } from '../../../models';

@Component({
    selector: 'app-site-tripbanner',
    templateUrl: './site-trip-banner.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SiteTripBannerComponent implements OnChanges {

    @Input('tripBanners') tripBanners: TripBanner[];

    constructor(
        public imageHelper: ImageHelper
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.tripBanners && this.tripBanners.length > 0) {
            this.tripBanners.sort((a, b) => a.order - b.order);
        }
    }

    urlBuitIn(tripBanner: any): void {
        let url = new URL(window.location.href);
        let baseUrl = `${url.origin}/onibus/${LocationHelper.beautifyLocationName(tripBanner.departureLocationName)}/` 
        + `${LocationHelper.beautifyLocationName(tripBanner.arrivalLocationName)}` 
        + `?departureDate=${tripBanner.priceDate.day}-${tripBanner.priceDate.month}-${tripBanner.priceDate.year}`;
        url.searchParams.forEach((value, key)=>{
            baseUrl +=`&${key}=${value}`; 
        });
        window.location.href = baseUrl; 
    }
}
