import moment from 'moment';
import { Trip } from './trip';

export class OutletTripRange {
    startingSaleDateTime: string;
    endingSaleDateTime: string;
    bestTrip: Trip;

    constructor(
        startingSaleDateTime: Date,
        endingSaleDateTime: Date,
        bestTrip: Trip
    ) {
        this.startingSaleDateTime = moment(startingSaleDateTime).format('HH:mm');
        this.endingSaleDateTime = moment(endingSaleDateTime).format('HH:mm');
        this.bestTrip = bestTrip;
    }
}