import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Customer, ICompanyConfig } from 'src/app/main/models';
import { ConfigService, StorageService } from 'src/app/main/services';

@Component({
    selector: 'app-customer-menu',
    templateUrl: './customer-menu.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomerMenuComponent {
    @Input('customer') customer: Customer;
    @ViewChild('submenu') submenu: ElementRef;
    userName: string;
    public config: ICompanyConfig;

    constructor(
        private storage: StorageService,
        private router: Router,
        private configService: ConfigService
    ) {
        this.config = configService.company;
    }

    ngOnInit(): void {
        this.customer = this.storage.getLoggedCustomer();
        this.userName = this.customer.name.substring(0, (this.customer.name.indexOf(' ')));
    }

    logout = (): void => {
        this.storage.setLoggedCustomer(null, this.router);
    }

    redirect = (url, event): void => {
        this.router.navigate([url]);
        
        let ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
            this.submenu.nativeElement.setAttribute('style','display:none');
            event.stopPropagation();
        }
    }
    
    openSubMenu = (): void => {
        let ua = navigator.userAgent;
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)){
            this.submenu.nativeElement.setAttribute('style','display:flex');
        }
    }
}
