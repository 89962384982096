import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyLogo } from '../models';
import { BaseApi } from './base.api';
import { BaseService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class LogoService {

    public api: BaseApi;

    public constructor(
        base: BaseService
    ) {
        this.api = base.setApi('gateway', 'core/companies');
    }

    public getCompanyLogo(ids: number[]): Observable<{ logos:CompanyLogo[] }> {
        return this.api.post('logo', ids);
    }

}
