import { CurrencyPipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { ConfigService } from "../services";

@Pipe({
    name: "oreonscurrency"
})
export class OreonsCurrencyPipe extends CurrencyPipe implements PipeTransform {
    
    public constructor(
        private configService: ConfigService
    ) {
        super("pt");
    }

    // public transform(value: number | string, code?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null  {
    //     // if (environment.idTenant === 2) {
    //     //     code = 'EUR';
    //     // }
    //     // return super.transform(value, code, display, digitsInfo, locale);
    //     return <any>1;
    // }


    transform(value: number | string, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null;
    transform(value: null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): null;
    transform(value: number | string | null | undefined, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): string | null;
    transform(
        value: any,
        code = "BRL",
        display = "symbol",
        digitsInfo = "1.2-2",
        locale = "pt"
    ) {
        if (this.configService.company.idTenant === 2) {
            code = 'EUR';
        }
        return super.transform(value, code, display, digitsInfo, locale);
    }
}