
export class OreonsCurrency {
    
    public currencyInfo: OreonsCurrencyInfo;

    constructor(
        public currencyValue: number,
        idCurrencyInfo: number
    ) {
        this.currencyInfo = new OreonsCurrencyInfo(idCurrencyInfo);
    }
}

export class OreonsCurrencyInfo {

    public constructor(
        public idCurrencyInfo: number
    ) {
    }
}