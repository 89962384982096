import { Component, OnInit, } from '@angular/core';
import { BaseService, ConfigService } from '../../../services';

@Component({
    selector: 'whatsapp-link',
    templateUrl: 'whatsapp-link.component.html'
})
export class WhatsAppLinkComponent implements OnInit {

    public whatsCollapssed: boolean = false;

    public constructor(
        private base: BaseService,
        private configService: ConfigService
    ) { }

    public ngOnInit(): void {
        /*setTimeout(() => { this.whatsCollapssed = true }, 7000);*/
    }

    public isWhatsAppVisible(): boolean {
        return this.base.configService.company.showWhatsApp;
    }

    public getWhatsAppText(): string {
        return this.base.configService.company.whatsAppText;
    }

    openWhatsApp() {
        let ua = navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
            window.open('whatsapp://send?' + this.configService.company.whatsAppLink, '_blank');
        } else {
            window.open('https://api.whatsapp.com/send?' + this.configService.company.whatsAppLink, '_blank');
        }
    }
}
