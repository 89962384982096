<div class="row" *ngIf="tripBanners.length > 0">
    <div class="col">
        <div class="main-routes">
            <h1 class="main-routes-title">
                {{ 'common.mostSearchedRoutes' | translate}}
                <a class="main-routes-title-link" [routerLink]="['/onibus']">Ver todas</a>
            </h1>
            <div class="row">
                <div [ngClass]="tripBanner.IsFeatured == true ? 'col-sm-12 col-lg-8 promotional-banner' : 'col-sm-12 col-lg-4 promotional-banner'"
                    *ngFor="let tripBanner of tripBanners; let i = index;">
                    <a *ngIf="tripBanner.price != null && tripBanner.price.currencyValue != null" 
                        (click)="urlBuitIn(tripBanner)" class="route">
                        <div class="route-info">
                            <span class="label">De {{tripBanner.departureLocationSimpleName | uppercase}}
                                para</span>
                            <b class="value">{{tripBanner.arrivalLocationSimpleName | titlecase}}</b>
                        </div>
                        <img class="route-image" [src]="imageHelper.getImageLocation(tripBanner.imageToken)" />
                        <h4
                            [ngClass]="tripBanner.isFeatured ? 'route-title-destination feature' : 'route-title-destination'">
                            A PARTIR DE<span class="price">{{tripBanner.price.currencyValue | oreonscurrency}}</span>
                        </h4>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>