import { ICompanyConfig } from "../../../main/models/config/company-config";

export class CustomerRegisterEvent {
    readonly fullName: string;
    readonly email: string;
    readonly document: string;
    readonly phone: string;
    readonly pass: string;
    readonly passConfirmation: string;
    readonly idInformationMetadataConfiguration: number;
    readonly idInformationMetadataField: number;
    readonly idCountry: number;
    readonly countryCode: string;
    readonly origin: number;
    readonly idCity: number;
    readonly idState: number;
    readonly birthday: Date;
    readonly acceptCommunication: boolean;

    constructor(
        fullName: string,
        email: string,
        document: string,
        phone: string,
        pass: string,
        passConfirmation: string,
        idCity: number,
        idState: number,
        birthday: Date,
        idCountry:number,
        countryCode:string,
        acceptCommunication:boolean,
        config: ICompanyConfig
    ) {
        this.fullName = fullName;
        this.email = email;
        this.document = document;
        this.phone = phone;
        this.pass = pass;
        this.passConfirmation = passConfirmation;
        this.idCity = idCity;
        this.idState = idState;
        this.birthday = birthday;
        this.idInformationMetadataConfiguration = config.customerInformationMetadataConfiguration;
        this.idInformationMetadataField = config.customerInformationMetadataField;
        this.idCountry = config.idTenant !== 2 ? config.countryId : idCountry;
        this.countryCode = config.idTenant !== 2 ? '55' : countryCode;
        this.origin = config.origin;
        this.acceptCommunication = acceptCommunication;
    }
}