export interface BookingProcessStage {
    stage?: ProcessStage
}

export enum ProcessStage {
    selectingTrip = 0,
    selectingBackTrip = 1,
    checkout = 2,
    conclusion = 3,
    extras = 4,
    checkout3Ds = 5
}