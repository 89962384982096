import { ICompanyConfig } from '../config/company-config';
import { OreonsDatetime } from '../helpers/oreons-datetime';
import { CustomerConsent } from './customer-consent';
import { CustomerDocument } from './customer-document';

export class Customer {
    idCustomer: number;
    email: string;
    documentValue: string;
    identifier: string;
    name: string;
    idCountry: number;
    idCity: number;
    idState: number;
    countryCode: string;
    stateName: string;
    cityName: string;
    phoneNumber: string;
    document: string;
    informationMetadataConfiguration: number;
    birthDate: OreonsDatetime;
    informationMetadataField: number;
    visitor: string;
    documents: CustomerDocument[] = [];
    phones: any[] = [];
    consents: CustomerConsent[] = [];

    constructor(
        config: ICompanyConfig
    ) {
        this.informationMetadataConfiguration = config.customerInformationMetadataConfiguration;
        this.informationMetadataField = config.customerInformationMetadataField;
        this.idCountry = config.countryId;
    }
}