export class AddPaymentInfo{
    event: string = 'addPaymentInfo';

    constructor(
        public coupon:string,
        public currency:string,
        public payment_type:string,
        public value:number,){
    }
}
