<ngb-datepicker class="popup" *ngIf="display" [minDate]="getMinDate()" [maxDate]="getMaxDate()" (dateSelect)="onDateSelection($event)"
                [dayTemplate]="customTemplate" [footerTemplate]="footerTemplate" name="dp" ngbDatepicker autoClose=true
                #datePicker="ngbDatepicker"></ngb-datepicker>

<ng-template #customTemplate let-date let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
        {{ date.day }}
        <span class="calendar-day-price" *ngIf="hasMinimumPrice(date)">{{ getMinimumPrice(date) | oreonscurrency }}</span>
    </span>
</ng-template>

<ng-template #footerTemplate>
    <span class="calendar-footer-text" *ngIf="customFooterLabel">{{customFooterLabel}}</span>
    <span *ngIf="!customFooterLabel" class="calendar-footer-text">Escolha a data de <b>{{subtitle}}</b></span>
</ng-template>
