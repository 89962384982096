import { Serializer } from "../models/helpers/serializer";

export class ScheduleLocationSerializer implements Serializer {
    fromJson = (json: any): any => {

    }

    fromJsonCollection = (json: any): any[] => {
        return json;
    }
}